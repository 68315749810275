<template>
  <div class="csn-pnp-account">
    <div class="modal-dialog csn-modal-dialog">
      <div class="modal-content">
        <button
          class="btn csn-modal-close-button"
          @click="handleCloseButtonClick"
        >
          <CloseIcon />
        </button>

        <div class="modal-header">
          <h5 class="modal-title">
            {{ t('pay_and_play') }}
          </h5>
        </div>
        <div class="modal-body">
          <PnpAccount @close="closeModal" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { PNP_ACCOUNT_MODAL } from '@/constants'
import { modalMixin } from '@/mixins'

export default {
  name: PNP_ACCOUNT_MODAL,
  components: {
    CloseIcon: () => import('@/components/svg/CloseIcon'),
    PnpAccount: () => import('@/components/PnpAccountContent'),
  },
  mixins: [modalMixin],
  computed: {
    t() {
      return this.$createComponentTranslator(PNP_ACCOUNT_MODAL)
    },
  },
  methods: {
    closeModal(fn) {
      return this.$emit('close', fn)
    },
    handleCloseButtonClick() {
      this.closeModal(this.goToHomepage)
    },
  },
}
</script>
